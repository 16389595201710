import type React from 'react';

// Dynamically attach axe-core/react to ReactDOM rendering.
// If we're in development, and in the browser (not SSR.)
export const attachLogging = async (
  App: typeof React,
  config: Record<string, unknown> = {}
) => {
  if (process.env.NODE_ENV !== 'development' || typeof window === 'undefined') {
    return;
  }
  const axe = await import('@axe-core/react');
  const ReactDOM = await import('react-dom');

  const finalConfig = {
    runOnly: ['wcag21a', 'wcag21aa', 'wcag22aa', 'best-practice'],
    ...config,
  };

  axe.default(App, ReactDOM, 1000, finalConfig);
};
