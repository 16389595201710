import {
  type PropsWithChildren,
  createContext,
  useContext,
  type FunctionComponent,
} from 'react';
import * as staticFeatures from '@/lib/utils/features';

interface FeatureFlags extends Record<string, boolean> {}

export interface FeatureFlagProps extends PropsWithChildren {
  overrides?: FeatureFlags;
}

const FeatureFlagContext = createContext<FeatureFlags>({});

export const FeatureFlagProvider: FunctionComponent<FeatureFlagProps> = ({
  overrides = {},
  children,
}) => {
  const flags = {
    ...staticFeatures,
    ...overrides,
  };

  return (
    <FeatureFlagContext.Provider value={flags}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

export const useFeatureFlag = (flag: string): boolean => {
  const flags = useContext(FeatureFlagContext);
  return !!flags[flag];
};
