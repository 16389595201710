import type { ProgramConfig } from './types';

const upliv: ProgramConfig = {
  programName: 'Upliv',
  programId: '6f3fd233-53f4-3e1d-b38e-3083917b67d8',
  programSlug: 'upliv',
  programTheme: 'upliv',
  contentfulTag: 'platformUpliv',

  privacyPolicyUrl: 'https://www.uplivhealth.com/privacy-notice',
  termsOfServiceUrl: 'https://www.uplivhealth.com/terms-and-conditions',
  alphaTermsUrl:
    'https://www.caire.health/terms-and-conditions-for-upliv-alpha-test',

  welkinCloudLink: 'https://care.live.welkincloud.io/signin',

  noticeOfPrivacyPracticesUrl:
    'https://www.uplivhealth.com/notice-of-privacy-practices',
  telehealthConsentUrl: 'https://www.uplivhealth.com/telehealth-consent',
  supportPhoneNumber: '6193771244',
  supportEmail: 'upliv-support@caire.health',
  supportFaqUrl: 'https://www.uplivhealth.com/faq',

  programLogo: {
    url: '/images/upliv/logo-on-dark.svg',
    height: 37,
    width: 84,
    alt: 'Upliv',
  },

  lightProgramLogo: {
    url: '/images/upliv/logo-on-light.svg',
    height: 37,
    width: 84,
    alt: 'Upliv',
  },
};

export default upliv;
