import {
  type PropsWithChildren,
  type FunctionComponent,
  type Dispatch,
  createContext,
  useEffect,
  useReducer,
  useContext,
  useCallback,
} from 'react';
import { useAuth0 } from '@auth0/auth0-react';

/* eslint-disable unused-imports/no-unused-vars --
   Workaround for eslint-plugin-unused-imports bug mishandling TypeScript enums. */
export enum TokenUpdateActionType {
  PendingAuthentication,
  UnauthenticatedUser,
  TokenRefreshError,
  FetchToken,
  ReceiveToken,
  InvalidateToken,
}
/* eslint-enable unused-imports/no-unused-vars */

export interface TokenUpdateAction {
  type: TokenUpdateActionType;
  authToken?: string;
  error?: Error;
}

export type AuthenticationState = {
  isLoading: boolean;
  isTokenInvalidated: boolean;
  isReady: boolean;
  authToken: string;
  error: Error | null;
};

const initialAuthenticationState = {
  isLoading: true,
  isReady: false,
  isTokenInvalidated: false,
  authToken: '',
  error: null,
};

const stub = (): never => {
  throw new Error('You must wrap your component in <AuthenticationProvider>.');
};

export type AuthenticationUpdate = Dispatch<TokenUpdateAction>;

/**
 * AuthenticationStateContext stores the current JWT token state.
 */
const AuthenticationStateContext = createContext<AuthenticationState>(
  initialAuthenticationState
);

/**
 * AuthenticationStateContext exposes the the JWT state update dispatcher.
 */
const AuthenticationUpdateContext = createContext<AuthenticationUpdate>(stub);

export const tokenUpdateReducer = (
  state: AuthenticationState,
  action: TokenUpdateAction
): AuthenticationState => {
  switch (action.type) {
    case TokenUpdateActionType.PendingAuthentication:
      return {
        isLoading: true,
        isReady: false,
        isTokenInvalidated: false,
        authToken: '',
        error: null,
      };
    case TokenUpdateActionType.UnauthenticatedUser:
      return {
        isLoading: false,
        isReady: true,
        isTokenInvalidated: false,
        authToken: '',
        error: null,
      };
    case TokenUpdateActionType.TokenRefreshError:
      return {
        ...state,
        isLoading: false,
        isReady: true,
        isTokenInvalidated: false,
        authToken: '',
        error: action.error || null,
      };
    case TokenUpdateActionType.FetchToken:
      return {
        ...state,
        isLoading: true,
        isReady: false,
        error: null,
      };
    case TokenUpdateActionType.ReceiveToken:
      return {
        ...state,
        isLoading: false,
        isReady: true,
        authToken: action.authToken || '',
        isTokenInvalidated: false,
        error: null,
      };
    case TokenUpdateActionType.InvalidateToken:
      return {
        ...state,
        isTokenInvalidated: true,
        authToken: '',
        error: null,
      };
    default:
      // istanbul ignore next
      return state;
  }
};

export interface AuthenticationProviderProps extends PropsWithChildren {
  // a dummy token, set instead of fetching via the auth0 promise. Provided
  // here to help keeps tests synchronous.
  testToken?: string;
  // The authentication audience requested from token refreshes
  audience: string;
}

export const AuthenticationProvider: FunctionComponent<
  AuthenticationProviderProps
> = ({ audience, testToken, children }) => {
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [state, dispatch] = useReducer(
    tokenUpdateReducer,
    initialAuthenticationState
  );
  const isTokenValid = !state.isTokenInvalidated;
  const hasToken = !!state.authToken;

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      dispatch({ type: TokenUpdateActionType.UnauthenticatedUser });
    }
    if (isLoading && !isAuthenticated) {
      dispatch({ type: TokenUpdateActionType.PendingAuthentication });
    }
  }, [isLoading, isAuthenticated]);

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    if (testToken) {
      dispatch({ type: TokenUpdateActionType.FetchToken });
      dispatch({
        type: TokenUpdateActionType.ReceiveToken,
        authToken: testToken,
      });
      return;
    }

    if (!isTokenValid || !hasToken) {
      dispatch({ type: TokenUpdateActionType.FetchToken });
      getAccessTokenSilently({
        authorizationParams: {
          audience,
        },
        cacheMode: 'off',
      })
        .then((token) => {
          if (token) {
            dispatch({
              type: TokenUpdateActionType.ReceiveToken,
              authToken: token,
            });
          }
        })
        .catch((error) => {
          dispatch({ type: TokenUpdateActionType.TokenRefreshError, error });
        });
    }
  }, [
    isAuthenticated,
    getAccessTokenSilently,
    isTokenValid,
    audience,
    testToken,
    hasToken,
  ]);

  return (
    <AuthenticationStateContext.Provider value={state}>
      <AuthenticationUpdateContext.Provider value={dispatch}>
        {children}
      </AuthenticationUpdateContext.Provider>
    </AuthenticationStateContext.Provider>
  );
};

export const useAuthentication = () => {
  const state = useContext(AuthenticationStateContext);
  const dispatch = useContext(AuthenticationUpdateContext);

  const { isLoading, isReady, authToken, error } = state;
  const invalidateToken = useCallback(() => {
    dispatch({ type: TokenUpdateActionType.InvalidateToken });
  }, [dispatch]);

  return {
    isLoading,
    isReady,
    authToken,
    hasToken: !!authToken,
    error,
    invalidateToken,
  };
};
