import type { FunctionComponent } from 'react';
import { Lato } from 'next/font/google';

const customFont = Lato({
  weight: ['100', '400', '700'],
  style: ['normal', 'italic'],
  subsets: ['latin'],
  variable: '--fontFamily',
});

/**
 * Load and declare the custom font for Caire's design.
 * @returns Inline variable configuration for Caire theme custom font-family.
 */
const CaireFont: FunctionComponent = () => (
  <style jsx global>{`
    :root {
      --fontFamily: ${customFont.style.fontFamily};
    }
  `}</style>
);

export default CaireFont;
