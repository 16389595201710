import type { ProgramConfig } from './types';
import demoBase from './demo-base';

const msk: ProgramConfig = {
  ...demoBase,
  programId: '8a73bb5a-6a1a-3870-b66d-f045aee3c27a',
  programSlug: 'msk',
};

export default msk;
