import type { ProgramConfig } from './types';
export type { ImageConfig, ProgramConfig } from './types';

import hh from './hh';
import msk from './msk';
import maternal from './maternal';
import nwd from './nwd';
import upliv from './upliv';

// Program falls back to `upliv` to ensure compatibility with productions state.
const program = process.env.NEXT_PUBLIC_PROGRAM || 'upliv';

export const allConfigs: Record<string, ProgramConfig> = {
  // Upliv first to ensure loaded before any other program with the same UUID.
  upliv,
  // New programs listed alphabetically.
  hh,
  maternal,
  msk,
  nwd,
};

if (!Object.hasOwn(allConfigs, program)) {
  throw new Error(
    `Fatal error: Environment specified unknown program identifier: ${program}`
  );
}

const currentConfig: ProgramConfig = allConfigs[program];

export default currentConfig;

export const programIdForSlug = (slug: string): string =>
  allConfigs[slug]?.programId ?? undefined;
