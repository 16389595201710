import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import type { Client, Message } from '@twilio/conversations';
import { buildConversationPreview } from '@/lib/twilio/formatter';
import useAuthenticatedQuery from '@/hooks/useAuthenticatedQuery';
import { useConversationUserAccessTokenQuery } from '@/lib/graphql/shared';
import { StaffRole } from '@/lib/graphql/types';
import { ConversationPreviewInterface } from '@/components/Messages/ConversationPreview/ConversationPreview';

interface Props {
  children: ReactNode;
  userPerspective: 'patient' | 'staff';
}

export type UserRole = 'patient' | StaffRole;

type ConnectionError = {
  terminal: boolean;
  message: string;
};

type TwilioContext = {
  client?: Client;
  error?: ConnectionError; // TODO: refactor to return a actual error object CRE-1510
  hasUnreadMessages: boolean;
  identity: { id?: string; role?: UserRole };
  userPerspective: Props['userPerspective'];
};

interface CustomJwtPayload {
  grants?: {
    identity?: string;
  };
}

const TwilioClientContext = createContext<TwilioContext>({
  client: undefined,
  error: undefined,
  hasUnreadMessages: false,
  identity: {},
  userPerspective: 'patient',
});

export const useTwilio = () => {
  return useContext(TwilioClientContext);
};

const hasUnreadConversations = async (
  client: Client,
  userPerspective: 'patient' | 'staff'
): Promise<boolean> => {
  try {
    const conversations = (await client
      .getSubscribedConversations()
      .then(({ items }) => items)
      .then((conversations) =>
        conversations.map((conversation) =>
          buildConversationPreview(conversation, userPerspective)
        )
      )
      .then((pendingPreviews) => Promise.allSettled(pendingPreviews))
      .then((previews) =>
        previews.flatMap(
          (cp) =>
            // Remove any `null` or invalid status conversations from the list and
            // distill to `value` property
            'value' in cp && cp.status === 'fulfilled' ? cp.value : []
          // safe to restate type as null values are removed with `flatMap` above.
        )
      )) as ConversationPreviewInterface[];

    return conversations.some(
      (preview) => preview?.showUnreadIndicator === true
    );
  } catch (error) {
    return false;
  }
};

const TwilioProvider = ({ children, userPerspective }: Props) => {
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
  const [error, setError] = useState<TwilioContext['error']>();
  const [client, setClient] = useState<Client>();
  const [identity, setIdentity] = useState<TwilioContext['identity']>({});

  const { data: token } = useAuthenticatedQuery(
    useConversationUserAccessTokenQuery
  );

  useEffect(() => {
    if (!token) {
      return;
    }

    const twilioToken = token?.conversationUserAccessToken.accessToken;

    // Twilio token is a three part string separated by dots represented by: [header, payload, signature]
    const [, payload] = twilioToken.split('.');
    // Decode payload and parse into JSON object
    const decodedToken: CustomJwtPayload = JSON.parse(
      Buffer.from(payload, 'base64').toString()
    );

    import('@twilio/conversations').then(({ Client }) => {
      const newClient = new Client(twilioToken);

      // NB. We bind to Twilio events here without unbinding them, however, `initialized`
      // and `initFailed` is only fired once in the client lifecycle, so this is safe.
      newClient.on('initialized', () => {
        setClient(newClient);
        if (decodedToken.grants?.identity) {
          const [role, id] = decodedToken.grants?.identity.split('|') || [];
          setIdentity({ id, role: role as UserRole });
        }
      });

      newClient.on('initFailed', ({ error }) => {
        if (error) {
          setError(error);
        }
      });
    });
  }, [token]);

  useEffect(() => {
    if (!client) {
      return;
    }
    hasUnreadConversations(client, userPerspective).then(setHasUnreadMessages);
  }, [client, userPerspective]);

  useEffect(() => {
    if (!client) {
      return;
    }

    const messageAddedHandler = (message: Message) => {
      const [userType] = (message.author ?? '').split('|');
      if (userType && userType != userPerspective) {
        setHasUnreadMessages(true);
      }
    };

    client.on('messageAdded', messageAddedHandler);
    return () => void client.off('messageAdded', messageAddedHandler);
  }, [client, userPerspective]);

  useEffect(() => {
    if (!client) {
      return;
    }

    const conversationUpdatedHandler = () => {
      hasUnreadConversations(client, userPerspective).then(
        setHasUnreadMessages
      );
    };

    client.on('conversationUpdated', conversationUpdatedHandler);
    return () =>
      void client.off('conversationUpdated', conversationUpdatedHandler);
  }, [client, userPerspective]);

  return (
    <TwilioClientContext.Provider
      value={{
        client,
        error,
        hasUnreadMessages,
        identity,
        userPerspective,
      }}>
      {children}
    </TwilioClientContext.Provider>
  );
};

export default TwilioProvider;
