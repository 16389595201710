import type { ProgramConfig } from './types';
import demoBase from './demo-base';

// Huntington Hospital universal demo program
const huntington: ProgramConfig = {
  ...demoBase,
  programId: 'b8ac0498-d478-3f8a-b138-4040418eb778',
  programSlug: 'huntington_demo',
};

export default huntington;
