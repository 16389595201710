import {
  OperationVariables,
  QueryHookOptions,
  QueryResult,
} from '@apollo/client';
import { useAuthentication } from '@/contexts/Authentication';
import { Optional } from '@/types/generics';
import { GeneratedUseQueryHook } from '@/types/genericQueries';

/**
 * __useAuthenticatedQuery__
 *
 * Run a GraphQL query once user authentication and JWT request tokens have been initialized.
 * Ensures that queries are not executed before authentication state is complete, with a user
 * running in the browser, not in server-side prebuild, and with a valid JWT token applied to
 * the Apollo client.
 *
 * Note, if the user is not authenticated or no token is initialized, this hook will simply
 * remain idle.
 *
 * The `loading` property of the query return will reflect the loading authentication state
 * as well as the loading state of the query itself.
 *
 * @param queryFunction the query function to run once authenticated
 * @param options options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthenticatedQuery(useStaffQuery, {
 *   variables: {
 *      staffId: // value for 'staffId'
 *   },
 * });
 */
const useAuthenticatedQuery = <
  TData,
  TVariables extends OperationVariables,
  AwaitedVariables extends string
>(
  queryFunction: GeneratedUseQueryHook<TData, TVariables>,
  options: QueryHookOptions<TData, Optional<TVariables, AwaitedVariables>> & {
    waitFor?: AwaitedVariables[];
  } = {}
): QueryResult<TData, TVariables> => {
  const { isLoading, hasToken } = useAuthentication();

  // Resolve waitFor fields
  const waitingForVariables = options.waitFor
    ? options.waitFor.some(
        (field) =>
          options.variables?.[field] === undefined &&
          options.variables?.[field] !== null
      )
    : false;

  const fullOptions = {
    ...(options as unknown as QueryHookOptions<TData, TVariables>),
    ssr: false, // Never authenticated in SSR initial build
    skip: waitingForVariables || !hasToken || !!options.skip, // Skip query until authentication token is in place
  };
  const functionState = queryFunction(fullOptions);
  return {
    ...functionState,
    loading: isLoading || functionState.loading,
  };
};

export default useAuthenticatedQuery;
