import { useRouter } from 'next/router';

/**
 * Wrapper hook to interface with next/router#query without having to wrangle
 * string vs string[] values.
 *
 * @returns get and getAll accessors for next/router#query
 */
const useQueryString = <QueryParamName extends string>(
  ...params: QueryParamName[]
): Record<QueryParamName, string | undefined> & {
  isReady: boolean;
  get: (key: string) => string | undefined;
  getAll: (key: string) => string[];
  remove: (key: string | string[]) => void;
  set: (params: Record<string, string | null | undefined>) => void;
} => {
  const { isReady, query, push } = useRouter();

  const get = (key: string): string | undefined => {
    if (!isReady) {
      return undefined;
    }
    const value = query[key];
    if (Array.isArray(value)) {
      return value[0] || undefined;
    }
    return value || undefined;
  };

  const getAll = (key: string): string[] => {
    if (!isReady) {
      return [];
    }
    const value = query[key];
    if (value === undefined) {
      return [];
    }
    if (Array.isArray(value)) {
      return value;
    }
    return [value];
  };

  const remove = (key: string | string[]) => {
    const newQuery = { ...query };

    if (typeof key === 'string') {
      delete newQuery[key];
    } else {
      key.forEach((k) => delete newQuery[k]);
    }

    push({ query: newQuery });
  };

  const set = (params: Record<string, string | null | undefined>) => {
    const newQuery = { ...query, ...params };

    Object.keys(newQuery).forEach((key) => {
      if (!newQuery[key]) {
        delete newQuery[key];
      }
    });

    push({ query: newQuery });
  };

  return {
    isReady,
    get,
    getAll,
    remove,
    set,
    // NB. Because fromEntries type is always Record<string, T>, we have to cast
    // to Record<QueryParamName, ...> to maintain the return type.
    ...(Object.fromEntries(
      params.map((param) => [param, get(param)])
    ) as Record<QueryParamName, string | undefined>),
  };
};

export default useQueryString;
