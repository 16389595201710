import React from 'react';
import type { FunctionComponent } from 'react';
import type { AppPropsWithLayout } from '@/types/AppWithLayout';
import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import caireTheme from '@/lib/style/caire-theme';
import CaireStyle from '@/components/CaireStyle';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import AuthenticatedApolloProvider from '@/components/AuthenticatedApolloProvider';
import { useRouter } from 'next/router';
import { afterLoginRedirectUrl } from '@/lib/auth/redirects';
import { AuthenticationProvider } from '@/contexts/Authentication';
import { ModalsProvider } from '@mantine/modals';
import RealUserMetrics from '@/components/DataDog/RealUserMetrics';
import TwilioProvider from '@/contexts/Twilio';
import { FeatureFlagProvider } from '@/contexts/FeatureFlag';
import ComponentHierarchy, { component } from '@/components/ComponentHierarchy';
import { IdleSessionTimeout } from '@/components/IdleSessionTimeout/IdleSessionTimeout';
import { ProgramSwitchProvider } from '@/contexts/ProgramSwitch';
import { attachLogging as possiblyAttachA11yLogging } from '@/lib/a11y/axe-react-console';

/* N.B. NextJS requires that global styles be imported in _app.tsx, and cannot be
   refactored into a webapp-shared component. */
// Future: Consider only importing the styles for components we use to reduce bundle size.
import '@shared-module-asset/@mantine/core/styles.css';
import '@shared-module-asset/@mantine/carousel/styles.css';
import '@shared-module-asset/@mantine/dates/styles.css';
import '@shared-module-asset/@mantine/notifications/styles.css';

import '@/style/reset.css';
import '@/style/theme/index.css';
import '@/style/mantine-overrides/index.css';
import '@/lib/style/assessment/assessment.css';

const App: FunctionComponent<AppPropsWithLayout> = ({
  Component,
  pageProps,
}) => {
  const { replace } = useRouter();
  const domain: string | undefined =
    process.env.NEXT_PUBLIC_AUTH0_ISSUER_BASE_URL;
  const clientId: string | undefined = process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID;
  const audience: string | undefined = process.env.NEXT_PUBLIC_AUTH0_AUDIENCE;

  const apiUrl = process.env.NEXT_PUBLIC_GRAPH_API_URL;

  const datadogAppId = process.env.NEXT_PUBLIC_DATADOG_APP_ID;
  const datadogClientToken = process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN;
  const datadogEnvironment = process.env.NEXT_PUBLIC_ENVIRONMENT;
  const datadogEnabled =
    datadogAppId && datadogClientToken && datadogEnvironment;

  if (!domain || !clientId || !audience) {
    throw new Error('Auth0 environment variables not set');
  }

  if (!apiUrl) {
    throw new Error('GraphQL gateway environment variable not set');
  }

  /**
   * After auth0 returns from an authorization redirect, it will pass the
   * `returnTo` parameter through appState. If present, redirect to there,
   * otherwise, go to the default location.
   */
  const onAuthRedirectCallback = (appState?: AppState) => {
    replace(appState?.returnTo || afterLoginRedirectUrl);
  };

  // Extract PageWithLayout extensions to pass into provider hierarchy:
  const getLayout = Component.getLayout ?? ((page) => page);
  const { toastPosition = 'bottom-right' } = Component.appConfig ?? {};

  return (
    <>
      {datadogEnabled && (
        <RealUserMetrics
          applicationId={datadogAppId}
          clientToken={datadogClientToken}
          environment={datadogEnvironment}
          tracingUrls={[apiUrl]}
          serviceName="patient-portal"
        />
      )}
      <ComponentHierarchy
        items={[
          component(Auth0Provider, {
            domain,
            clientId,
            onRedirectCallback: onAuthRedirectCallback,
            authorizationParams: {
              redirect_uri: process.env.NEXT_PUBLIC_AUTH0_REDIRECT_URI,
              audience,
            },
          }),
          component(AuthenticationProvider, { audience }),
          component(ProgramSwitchProvider),
          component(FeatureFlagProvider),
          component(AuthenticatedApolloProvider),
          component(IdleSessionTimeout),
          component(TwilioProvider, { userPerspective: 'patient' }),
          component(CaireStyle),
          component(MantineProvider, {
            theme: caireTheme,
            // We don't include Mantine's global styles, as we don't use Mantine's hiddenFrom/lightHidden tools
            withGlobalClasses: false,
            // We declare Mantine CSS variables in mantine-vars.css, rather than inline injection
            withCssVariables: false,
          }),
          component(ModalsProvider, {
            modalProps: {
              withinPortal: false,
            },
          }),
        ]}>
        <Notifications position={toastPosition} />
        {getLayout(<Component {...pageProps} />)}
      </ComponentHierarchy>
    </>
  );
};

possiblyAttachA11yLogging(React);

export default App;
