import type { ProgramConfig } from './types';

const nwd: ProgramConfig = {
  // NB. This config file currently uses Upliv program ID, slug and some other
  // identifiers to facilitate rendering upcoming Northwell look and feel against
  // existing Upliv data. We will update this when Northwell data becomes a distinct
  // entity in the platform fixtures.
  programId: '6f3fd233-53f4-3e1d-b38e-3083917b67d8',
  programSlug: 'upliv',
  programName: 'Northwell Direct',
  programTheme: 'nwd',
  contentfulTag: 'platformUpliv',

  privacyPolicyUrl: 'https://www.northwell.edu/privacy-policies-disclaimers',
  termsOfServiceUrl: 'https://www.northwell.edu/terms-of-service',
  alphaTermsUrl: '',
  welkinCloudLink: '',

  noticeOfPrivacyPracticesUrl: '',
  telehealthConsentUrl: '',

  supportPhoneNumber: '1234567890',
  supportEmail: 'support@example.com',
  supportFaqUrl: 'https://www.northwell.edu/help',

  programLogo: {
    url: '/images/nwd/logo-on-dark.svg',
    height: 31,
    width: 121,
    alt: 'Northwell Direct',
  },

  lightProgramLogo: {
    url: '/images/nwd/logo-on-light.svg',
    height: 31,
    width: 121,
    alt: 'Northwell Direct',
  },
};

export default nwd;
