import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import fetch from 'cross-fetch';
import isUuid from '@/lib/utils/isUuid';

let clientArgs: string = '';
let client: ApolloClient<any> | null = null;

/**
 * Insert an `authorization: bearer` header into an Apollo request
 * @param headers Record<string, string> The existing headers
 * @param token string The bearer token
 * @returns Record<string, string> The updated headers
 */
export const createAuthorizationHeader = (headers: {}, token?: string) => {
  if (!token) {
    return headers;
  }

  return {
    ...headers,
    authorization: `Bearer ${token}`,
  };
};

/**
 * Insert the x-caire-current-program header into an Apollo request
 * @param headers Record<string, string> The existing headers
 * @param program UUID The program UUID.
 * @returns Record<string, string> The updated headers
 */
export const createProgramHeader = (headers: {}, program: string) => {
  if (!program) {
    return headers;
  }
  if (!isUuid(program)) {
    throw Error('Program specified is not a valid uuid');
  }
  return {
    ...headers,
    'x-caire-current-program': program,
  };
};

/**
 * Create an HTTP link to the Caire GraphQL API.
 */
const caireHttpLink = createHttpLink({
  uri: process.env.NEXT_PUBLIC_GRAPH_API_URL,
  fetch,
});

const dataDogErrorReportLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors || networkError) {
    import('@datadog/browser-rum').then(({ datadogRum }) => {
      graphQLErrors?.forEach((error) => {
        datadogRum.addError(error);
      });
      if (networkError) {
        datadogRum.addError(networkError);
      }
    });
  }
});

const devConsoleErrorReportLink = onError(({ graphQLErrors, networkError }) => {
  if (process.env.NODE_ENV === 'development') {
    graphQLErrors?.forEach((error) => {
      console.error('GraphQL Error', error);
    });
    if (networkError) {
      console.error('GraphQL Network Error', networkError);
    }
  }
});

export const makeContentfulApiUrl = (
  baseUrl: string,
  spaceId: string,
  environmentName = 'master'
) =>
  [baseUrl, 'content/v1/spaces', spaceId, 'environments', environmentName].join(
    '/'
  );

/**
 * Create an HTTP link to the Contentful GraphQL API.
 * @example https://graphql.contentful.com/content/v1/spaces/{SPACE}
 * @example https://graphql.contentful.com/content/v1/spaces/{SPACE}/environments/{ENVIRONMENT}
 */
export const contentfulHttpLink = (() => {
  return createHttpLink({
    uri: makeContentfulApiUrl(
      process.env.NEXT_PUBLIC_CONTENTFUL_API_URL ||
        'https://graphql.contentful.com',
      process.env.NEXT_PUBLIC_CONTENTFUL_SPACE || '',
      process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT
    ),
    fetch,
  });
})();

/**
 * Contentful objects have an abnormal structure where the `id` is nested within
 * the `sys` object. This list of types is used below to generate the `typePolicies`
 * in the Apollo cache to normalize the data.
 */
const ContentfulIdTypes = [
  'GuidedJourney',
  'Lesson',
  'Page',
  'Celebration',
  'AccessibleMedia',
  'Category',
  'Tag',
];

/* Instantiate an In Memory Cache with the Contentful type policies */
export const makeCache = () =>
  new InMemoryCache({
    typePolicies: Object.fromEntries([
      ...ContentfulIdTypes.map((type) => [
        type,
        { keyFields: ['sys', ['id']] },
      ]),
    ]),
  });

export const configuredMemoryCache = makeCache();

/**
 *
 * @param token The current JWT authorization token for the Caire API
 * @param program The current Caire Program UUID
 * @returns ApolloClient
 */
const createApolloClient = (token: string, program: string | undefined) => {
  // Don't recreate the ApolloClient (clear cache) if arguments are the same:
  const argKey = `${token}:${program}`;
  if (client && clientArgs === argKey) {
    return client;
  }

  const auth0JWTLink = setContext(() => {
    return token
      ? {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      : {};
  });

  const contentfulAuthTokenLink = setContext(() => {
    const token = process.env.NEXT_PUBLIC_CONTENTFUL_TOKEN;
    return token
      ? {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      : {};
  });

  const caireProgramHeaderLink = setContext((_, previousContext) => {
    if (!program) {
      return previousContext;
    }

    const { headers } = previousContext;
    return {
      headers: createProgramHeader(headers, program),
    };
  });

  // Split the GraphQL backend target between Caire API and Contentful
  // based on the `apiName` context key
  const backendSplitLink = ApolloLink.split(
    (operation) => operation.getContext().apiName === 'contentful',
    ApolloLink.from([contentfulAuthTokenLink, contentfulHttpLink]),
    ApolloLink.from([auth0JWTLink, caireProgramHeaderLink, caireHttpLink])
  );

  client = new ApolloClient({
    link: ApolloLink.from([
      devConsoleErrorReportLink,
      dataDogErrorReportLink,
      backendSplitLink,
    ]),
    cache: configuredMemoryCache,
    ssrMode: typeof window === 'undefined',
    connectToDevTools: process.env.NODE_ENV === 'development' ? true : false,
  });

  // Store the arguments so we don't recreate this client unnecessarily:
  clientArgs = argKey;
  return client;
};

/**
 * Expose the ApolloClient instance, so it can be used in non-React hook code
 * paths, e.g. SurveyJS.
 * @returns ApolloClient The current ApolloClient instance.
 */
export const getApolloClient = () => client;

const APOLLO_CLIENT_READINESS_VERIFICATION_INTERVAL = 2500;

export const getApolloClientAsync = (): Promise<ApolloClient<any>> => {
  return new Promise((resolve) => {
    if (!client) {
      setTimeout(async () => {
        resolve(await getApolloClientAsync());
      }, APOLLO_CLIENT_READINESS_VERIFICATION_INTERVAL);

      return;
    }

    resolve(client);
  });
};

export default createApolloClient;
