/**
 *
 * @param value
 * @returns True if the value is a valid GUID, false otherwise.
 */
const isUuid = (value: string | any): boolean => {
  return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(
    value
  );
};

export default isUuid;
