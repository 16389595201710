import { createContext } from 'react';

export interface ProgramSwitchData {
  currentProgramId: string | undefined;
  activeProgramIds: string[];
  loading: boolean;
}

const ProgramSwitchContext = createContext<ProgramSwitchData>({
  currentProgramId: undefined,
  activeProgramIds: [],
  loading: true,
});

export default ProgramSwitchContext;
