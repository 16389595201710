import { useAuth0 } from '@auth0/auth0-react';
import { useIdle } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { ReactNode, useCallback, useEffect, useState } from 'react';

export function IdleSessionTimeout({ children }: { children: ReactNode }) {
  const { isAuthenticated, getIdTokenClaims, getAccessTokenSilently } =
    useAuth0();
  const [idleTimeout, setIdleTimeout] = useState<number | null>(null);

  const isIdle = useIdle((idleTimeout ?? 0) / 3);

  const keepAliveFunction = useCallback(() => {
    getAccessTokenSilently({ cacheMode: 'off' }).catch((error) => {
      if (error.message.includes('Login required')) {
        // TODO: CRE-1951: Add a blocking notification that the user is going to be logged out
        notifications.show({
          message:
            'You have been logged out due to a period of inactivity. Please log in again.',
          autoClose: false,
        });
      }
    });
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (isAuthenticated && !idleTimeout) {
      getIdTokenClaims()
        .then((claims) => {
          if (claims?.exp) {
            const expiresMs = claims.exp * 1000;
            const timeOut = Math.max(0, expiresMs - Date.now());

            setIdleTimeout(timeOut);
          }
        })
        .catch(() => {
          setIdleTimeout(null);
        });
    }
  }, [getIdTokenClaims, idleTimeout, isAuthenticated]);

  useEffect(() => {
    let keepAliveInterval: ReturnType<typeof setInterval> | undefined =
      undefined;
    if (!isIdle && isAuthenticated && idleTimeout) {
      keepAliveFunction();
      keepAliveInterval = setInterval(keepAliveFunction, idleTimeout / 2);
    } else {
      clearInterval(keepAliveInterval);
    }

    return () => clearInterval(keepAliveInterval);
  }, [keepAliveFunction, isAuthenticated, isIdle, idleTimeout]);

  return children;
}
