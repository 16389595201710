import type { PropsWithChildren, FunctionComponent } from 'react';
import cx from 'clsx';
import CaireFont from '@/components/CaireFont';
import useProgramConfig from '@/hooks/useProgramConfig';

type CaireStyleProps = PropsWithChildren &
  (
    | {
        enableProgramTheme: true;
        theme?: undefined;
      }
    | {
        theme?: string;
        enableProgramTheme?: false | undefined;
      }
  );

/**
 * Handler for common Caire theme style applications and font loading
 *
 * NextJS requires that global styles be imported in _app.tsx, and cannot be
 * refactored into a webapp-shared component. Therefore, you should also import the
 * following global stylesheets in your _app.tsx:
 *
 *     import '@shared-module-asset/normalize.css/normalize.css';
 *     import '@/style/global.css';
 *     import '@/style/theme.css';
 *
 */
const CaireStyle: FunctionComponent<CaireStyleProps> = ({
  children,
  theme = undefined,
  enableProgramTheme = theme ? false : true,
}) => {
  const { programTheme } = useProgramConfig();
  const themeName = theme || (enableProgramTheme && programTheme) || undefined;

  return (
    <>
      <CaireFont />
      <div
        className={cx('theme-root', {
          [`theme-${themeName}`]: !!themeName,
        })}
        data-mantine-color-scheme="light"
        data-testid="theme-element">
        {children}
      </div>
    </>
  );
};

export default CaireStyle;
