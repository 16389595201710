/**
 * Map environment variables to constants for use as feature flags.
 */
export const intakeSchedulePoc =
  process.env.NEXT_PUBLIC_FF_INTAKE_SCHEDULE_FLOW === 'true';
export const patientAlertSummaryDemo =
  process.env.NEXT_PUBLIC_FF_PATIENT_ALERTS_DEMO === 'true';
export const conversationalAi =
  process.env.NEXT_PUBLIC_FF_CONVERSATIONAL_AI === 'true';
export const signupDisabled =
  process.env.NEXT_PUBLIC_FF_DISABLE_SIGNUP === 'true';
