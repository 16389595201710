import type { ProgramConfig } from './types';
import demoBase from './demo-base';

const maternal: ProgramConfig = {
  ...demoBase,
  programId: '2cddc423-ab8f-36d9-954a-7d0dccb52888',
  programSlug: 'maternal_health',
};

export default maternal;
