import { useCurrentProgramContext } from '@/contexts/ProgramSwitch';
import { allConfigs, type ProgramConfig } from '@/lib/config';

type DynamicProgramConfig =
  | (ProgramConfig & { loading: false })
  | (Partial<ProgramConfig> & { loading: true });

const useProgramConfig = (): DynamicProgramConfig => {
  const { loading, currentProgramId } = useCurrentProgramContext();

  if (loading && !currentProgramId) {
    return {
      loading: true,
    };
  }

  const [, currentConfig] =
    Object.entries(allConfigs).find(
      ([, _]) => _.programId === currentProgramId
    ) ?? [];

  if (!currentConfig) {
    throw new Error(
      `Fatal Platform Error: No program config for program ID: ${currentProgramId}`
    );
  }

  return {
    loading: false,
    ...currentConfig,
  };
};

export default useProgramConfig;
