import type {
  ComponentType,
  FunctionComponent,
  PropsWithChildren,
  ReactNode,
} from 'react';

// Define type for the props of each provider component
type ComponentPropsWithoutChildren<T extends ComponentType<any>> =
  T extends ComponentType<infer P> ? Omit<P, 'children'> : never;

// Define type for each item in the provider array
export type HierarchyItem<T extends ComponentType<any>> =
  | [T, ComponentPropsWithoutChildren<T>]
  | [T];

// Define type for the provider array itself
type ComponentArray = Array<HierarchyItem<ComponentType<any>>>;

interface ProviderHierarchyProps extends PropsWithChildren {
  items: ComponentArray;
}

/**
 * This helper component takes a list of components with children, and renders them nested in a hierarchy. This
 * allows creating components with deep nested children (as in _App.tsx) without JSX indentation, making editing
 * simpler and diffs cleaner.
 *
 * In TypeScript, create component `items` using the `component` helper function rather than array
 * literals, as this will properly enforce the typing for the components props.
 */
const ComponentHierarchy: FunctionComponent<ProviderHierarchyProps> = ({
  items,
  children,
}) => {
  const nestComponents = (items: ComponentArray): ReactNode => {
    return items.reduceRight((child, [Component, props = {}]) => {
      return <Component {...props}>{child}</Component>;
    }, children);
  };
  return nestComponents(items);
};

/**
 * This function creates a Provider Item tuple [Component, Props] or [Component] for use in
 * the ProviderHierarchy component. It is a convenience function to make it easier to create
 * the ProviderItem tuple while enforcing the correct ProviderProps types.
 * @param component
 * @param props
 * @returns
 */
export const component = <T extends ComponentType<any>>(
  component: T,
  props?: ComponentPropsWithoutChildren<T>
): HierarchyItem<T> => {
  return props ? [component, props] : [component];
};

export default ComponentHierarchy;
